import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import one from "../../../../assets/images/ccp/avatar/one.svg";
import two from "../../../../assets/images/ccp/avatar/two.svg";
import three from "../../../../assets/images/ccp/avatar/three.svg";
import four from "../../../../assets/images/ccp/avatar/four.svg";
import five from "../../../../assets/images/ccp/avatar/five.svg";
import six from "../../../../assets/images/ccp/avatar/six.svg";
import seven from "../../../../assets/images/ccp/avatar/seven.svg";
import eight from "../../../../assets/images/ccp/avatar/eight.svg";
import nine from "../../../../assets/images/ccp/avatar/nine.svg";
import ten from "../../../../assets/images/ccp/avatar/ten.svg";
import eleven from "../../../../assets/images/ccp/avatar/eleven.svg";
import twelve from "../../../../assets/images/ccp/avatar/twelve.svg";

const avatars = [
  { src: one, number: 1 },
  { src: two, number: 2 },
  { src: three, number: 3 },
  { src: four, number: 4 },
  { src: five, number: 5 },
  { src: six, number: 6 },
  { src: seven, number: 7 },
  { src: eight, number: 8 },
  { src: nine, number: 9 },
  { src: ten, number: 10 },
  { src: eleven, number: 11 },
  { src: twelve, number: 12 },
];

const Leaderboards = ({ user, collegeName }) => {
  const navigate = useNavigate();
  const [oal, setOal] = useState([]);
  const [cl, setCl] = useState([]);
  const [cityLeaderboard, setCityLeaderboard] = useState([]);
  const [cityName, setCityName] = useState("");
  const [error, setError] = useState("");

  const fetchOverallLeaderboard = () => {
    Axios.get("https://ashish.moodi.org/ccp/oal", {
      headers: {
        "X-Requested-With": "moodi_ak297",
      },
    })
      .then((res) => {
        if (
          res.data.indexOf(
            res.data.find(({ userId }) => userId === user.userId)
          ) < 9
        ) {
          let dt = [
            { pos: 1, data: res.data[0] },
            { pos: 2, data: res.data[1] },
            { pos: 3, data: res.data[2] },
            { pos: 4, data: res.data[3] },
            { pos: 5, data: res.data[4] },
            { pos: 6, data: res.data[5] },
            { pos: 7, data: res.data[6] },
            { pos: 8, data: res.data[7] },
            { pos: 9, data: res.data[8] },
            { pos: 10, data: res.data[9] },
          ];
          setOal(dt);
        } else {
          let dt = [
            { pos: 1, data: res.data[0] },
            { pos: 2, data: res.data[1] },
            { pos: 3, data: res.data[2] },
            { pos: 4, data: res.data[3] },
            { pos: 5, data: res.data[4] },
            { pos: 6, data: res.data[5] },
            { pos: 7, data: res.data[6] },
            { pos: 8, data: res.data[7] },
            { pos: 9, data: res.data[8] },
            { pos: 10, data: res.data[9] },
            {
              pos:
                res.data.indexOf(
                  res.data.find(({ userId }) => userId === user.userId)
                ) + 1,
              data: res.data.find(({ userId }) => userId === user.userId),
            },
          ];
          setOal(dt);
        }
      })
      .catch((err) => console.error(err));
  };

  const string = user.collegeId;

  const str = "https://ashish.moodi.org/users/college/city?collegeId=" + string;

  const fetchCityName = () => {
    if (!user.collegeId) {
      setError("College ID is required.");
      return;
    }

    Axios.get(str, { headers: { "X-Requested-With": "moodi_ak297" } })
      .then((res) => {
        console.log("Response data:", res.data);
        if (res.data.cityName) {
          setCityName(res.data.cityName);
          setError("");
          setError("City name not found.");
        }
      })
      .catch((err) => {
        console.error("Error fetching city name:", err);
        if (err.response) {
          console.error("Response error:", err.response.data);
          setError(
            "Failed to fetch city name: " +
              (err.response.data.error || "Unknown error")
          );
        } else if (err.request) {
          console.error("No response received:", err.request);
          setError("No response from server.");
        } else {
          console.error("Request setup error:", err.message);
          setError("Request error: " + err.message);
        }
      });
  };

  const fetchCollegeLeaderboard = () => {
    Axios.get(`https://ashish.moodi.org/ccp/cl`, {
      headers: {
        "X-Requested-With": "moodi_ak297",
      },
    })
      .then((res) => {
        if (
          res.data.indexOf(
            res.data.find(({ collegeId }) => collegeId === user.collegeId)
          ) < 5
        ) {
          let dt = [
            { pos: 1, data: res.data[0] },
            { pos: 2, data: res.data[1] },
            { pos: 3, data: res.data[2] },
            { pos: 4, data: res.data[3] },
            { pos: 5, data: res.data[4] },
          ];
          setCl(dt);
        } else {
          let dt = [
            { pos: 1, data: res.data[0] },
            { pos: 2, data: res.data[1] },
            { pos: 3, data: res.data[2] },
            { pos: 4, data: res.data[3] },
            { pos: 5, data: res.data[4] },
            {
              pos: res.data.indexOf(
                res.data.find(({ collegeId }) => collegeId === user.collegeId)
              ),
              data: res.data.find(
                ({ collegeId }) => collegeId === user.collegeId
              ),
            },
          ];
          setCl(dt);
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchCityLeaderboard = () => {
    if (cityName) {
      Axios.get(
        `https://ashish.moodi.org/ccp/city_leaderboard?city=${cityName}`,
        {
          headers: {
            "X-Requested-With": "moodi_ak297",
          },
        }
      )
        .then((res) => {
          const data = res.data;
          const currentUserIndex = data.findIndex(
            ({ userId }) => userId === user.userId
          );
          const lastUser = data[data.length - 1]; // Get the last user in the data

          let dt;

          if (currentUserIndex < 5) {
            dt = [
              { pos: 1, data: data[0] },
              { pos: 2, data: data[1] },
              { pos: 3, data: data[2] },
              { pos: 4, data: data[3] },
              { pos: 5, data: data[4] },
              { pos: 6, data: data[5] },
              { pos: data.length, data: lastUser }, // Add the last user
            ];
          } else {
            dt = [
              { pos: 1, data: data[0] },
              { pos: 2, data: data[1] },
              { pos: 3, data: data[2] },
              { pos: 4, data: data[3] },
              { pos: 5, data: data[4] },
              {
                pos: currentUserIndex + 1,
                data: data[currentUserIndex],
              },
              { pos: data.length, data: lastUser }, // Add the last user
            ];
          }

          setCityLeaderboard(dt);
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (localStorage.getItem("miauthtoken")) {
      fetchCityName(); // Fetch the city name using collegeId
      fetchOverallLeaderboard();
      fetchCollegeLeaderboard();
      fetchCityLeaderboard();
    } else {
      navigate("/register");
    }
  }, [cityName]); // Re-fetch the city leaderboard when cityName changes
  const getAvatarImage = (number) => {
    const avatar = avatars.find((a) => a.number === number);
    return avatar ? avatar.src : null; // Return the avatar source or null if not found
  };

  return (
    <div className="leaderboard_page">
      <div className="leaderboard_group">
        <div className="ranks">
          <p className="rank_heading">OVERALL LEADERBOARD</p>
          <div className="ranked">
            {oal.map((element) => {
              return (
                <div
                  key={element.pos}
                  className={
                    element.data.userId === user.userId
                      ? `rank_row bg-indigo`
                      : `rank_row`
                  }
                >
                  <p>{element.pos}.</p>

                  <div className="position_leader">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="ava_le_img"
                        src={getAvatarImage(element.data.image)}
                        alt="avatar"
                      />
                      <p style={{ marginLeft: "1vw" }}>
                        {element.data.userName}
                      </p>
                    </div>
                    <p>{element.data.ccpPoints}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="leaderboard_group">
        <div className="ranks">
          <p className="rank_heading">COLLEGE LEADERBOARD</p>
          <div>
            {cl.map((element) => {
              return (
                <div
                  key={element.pos}
                  className={
                    element.data.collegeId === user.collegeId
                      ? `rank_row bg-indigo`
                      : `rank_row`
                  }
                >
                  <p>{element.pos}.</p>
                  <div className="position_leader">
                    <p>{element.data.collegeName}</p>
                    <p>{element.data.totalPoints}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="leaderboard_group">
        <div className="ranks">
          <p className="rank_heading">CITY LEADERBOARD</p>
          <div>
            {cityLeaderboard.map((element) => {
              return (
                <div
                  key={element.pos}
                  className={
                    element.data.userId === user.userId
                      ? `rank_row bg-indigo`
                      : `rank_row`
                  }
                >
                  <p>{element.pos}.</p>
                  <div className="position_leader">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        className="ava_le_img"
                        src={getAvatarImage(element.data.image)}
                        alt="avatar"
                      />
                      <p style={{ marginLeft: "1vw" }}>
                        {element.data.userName}
                      </p>
                    </div>
                    <p>{element.data.ccpPoints}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboards;
