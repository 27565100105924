import React, { useState, useRef, useEffect, useContext } from "react";
import "./css/dashboardhome.css";
import { useNavigate } from "react-router-dom";
import DashboardLeft from "./DashboardLeft";
import DashboardMid from "./DashboardMid";
import DashboardRight from "./DashboardRight";
import userContext from "../context/userContext";

const DashboardHome = () => {
  const navigate = useNavigate();
  const uc = useContext(userContext);
  const { user, getUser } = uc;
  const [currentclub, setCurrentClub] = useState("all Thread");
  const [currentPage, setCurrentPage] = useState("mymi");
  const dashMidRef = useRef(null);
  const dashRightRef = useRef(null);
  const updateRef = useRef(null);

  useEffect(() => {
    if (
      localStorage.getItem("miauthtoken") !== null &&
      localStorage.getItem("miauthtoken") !== undefined
    ) {
      getUser();
    } else {
      navigate("/register?redirect=ccp/mymi");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard_container bg-blue">
      <DashboardLeft
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        user={user}
        updateRef={updateRef}
      />
      <div className="dashboard_container_a">
        <DashboardMid
          page={currentPage}
          club={currentclub}
          user={user}
          dashMidRef={dashMidRef}
        />
        <DashboardRight
          currentclub={currentclub}
          setCurrentClub={setCurrentClub}
          page={currentPage}
          user={user}
          dashRightRef={dashRightRef}
        />
      </div>
    </div>
  );
};

export default DashboardHome;
