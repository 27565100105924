import React, { useEffect, useContext, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FirstPage from "./HomeComponents/FirstPage";
import SecondPage from "./HomeComponents/SecondPage";
import ThirdPage from "./HomeComponents/ThirdPage";
import LastPage from "./HomeComponents/LastPage";
import Testimonial from "./HomeComponents/Testimonials";
import FourthPage from "./HomeComponents/FourthPage";
import ResgisterCircle from "./HomeComponents/ResgisterCircle";
import ballon from "../../../assets/images/ccp/random_assets/ballon.svg";
import Entry from "../../../assets/images/entrypass.svg";
import "./style.css";
import "./home_responsive.css";
import { QRCodeCanvas } from "qrcode.react";
import Helps from "../../small_component/Helps";
import userContext from "../context/userContext";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Fix import issue

gsap.registerPlugin(ScrollTrigger);

const IndexHome = () => {
  const uc = useContext(userContext);
  const [showQRPopup, setShowQRPopup] = useState(false);
  const [userIds, setUserIds] = useState(null); // Store user ID separately
  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false); // State for login message
  const navigate = useNavigate();
  const { user, getUser } = uc;

  useEffect(() => {
    if (window.innerWidth <= 730) {
      gsap.to(".registercirlce", {
        xPercent: 60,
        duration: 12,
        scrollTrigger: {
          trigger: ".registercirlce",
          start: "top center",
          end: "+=60%",
          scrub: true,
        },
      });
    } else {
      gsap.to(".registercirlce", {
        xPercent: 80,
        duration: 12,
        scrollTrigger: {
          trigger: ".registercirlce",
          start: "top center",
          end: "+=100%",
          scrub: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 730) {
      // gsap to ".object_g" if required.
    } else {
      gsap.to(".object_g", {
        yPercent: 600,
        xPercent: -830,
        duration: 10,
        scrollTrigger: {
          trigger: ".object_g",
          start: "top",
          end: "+=90%",
          scrub: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("miauthtoken")) {
      getUser();
    }
  }, [getUser]);

  useEffect(() => {
    const token = localStorage.getItem("miauthtoken");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const decodedUserId = decodedToken.user.userId;
        setUserIds(decodedUserId); // Save userId from token
        setAlreadyLoggedIn(true); // User is already logged in
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
  }, [user]); // Trigger on user change

  const handleUser = () => {
    if (alreadyLoggedIn) {
      alert("You are already logged in."); // Show alert for already logged-in users
    } else if (userIds) {
      setShowQRPopup(true); // Only show QR if userIds are valid
    } else {
      navigate("/register");
    }
  };

  const closeQRPopup = () => {
    setShowQRPopup(false);
  };

  return (
    <div className="home_main" style={{ overflow: "hidden" }}>
      <div className="registercirlce" onClick={handleUser}>
        <ResgisterCircle />
      </div>
      <FirstPage />
      {/* <img src={Entry} className="entry_redireact" alt="entry" /> */}
      <SecondPage />
      <img className="object_g" src={ballon} alt="ballon" />
      <ThirdPage />
      <FourthPage />
      <Testimonial />
      <LastPage />
      <Helps />

      {showQRPopup && userIds && (
        <div className="qr-popup">
          <div className="qr-popup-content">
            <button className="close-btn_qr" onClick={closeQRPopup}>
              ×
            </button>
            <QRCodeCanvas value={userIds} size={256} />
            <p>Scan this QR code to verify the user</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndexHome;
