import React, { useRef, useState } from "react";
import Axios from "axios";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

function LoginInfo({ user, setUser, page, setPage, redirect }) {
  const nextBtnRef = useRef(null);
  let navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };

  const onEmailClick = () => {
    const nextBtn = nextBtnRef.current;
    nextBtn.click();
  };

  const handleCallbackResponse = (response) => {
    try {
      setSubmitDisabled(true);
      const userObject = jwtDecode(response.credential); // Decode JWT token

      Axios.post(
        "https://ashish.moodi.org/users/getUser",
        { email: userObject.email },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "moodi_ak297",
          },
        }
      )
        .then((res) => {
          if (res.data.exists) {
            localStorage.setItem("miauthtoken", res.data.authtoken);
            if (redirect) {
              if (redirect === "moodi") {
                console.log("Navigating to moodi.org");
                window.location.href = "https://moodi.org/";
              } else {
                console.log("Navigating to:", `/${redirect}`);
                navigate(`/${redirect}`);
              }
            } else {
              navigate("/success");
            }
          } else {
            setUser({
              ...user,
              email: userObject.email,
              googleId: userObject.sub,
              profilePic: userObject.picture,
            });
            setSubmitDisabled(false);
            const nextBtn = nextBtnRef.current;
            nextBtn.click();
          }
        })
        .catch((err) => {
          console.error("API Error:", err);
          setSubmitDisabled(false);
        });
    } catch (err) {
      console.error("JWT Decode Error:", err);
      setSubmitDisabled(false);
    }
  };

  return (
    <div className="logininfo">
      <div>
        <p className="logininfo_text">Log in to your Account</p>
        <p className="logininfo_text_a">
          Welcome Back! Select a method to log in:
        </p>
      </div>
      <div>
        {submitDisabled ? (
          <div>
            <span className="spinner"></span>
            <p>Logging in... Please wait</p>
          </div>
        ) : (
          <div className="google-login-wrapper">
            {/* -> localhost */}
            {/* <GoogleOAuthProvider clientId="571154717148-bj2dfukrsaij1rvmi3too5cd3jb82d9j.apps.googleusercontent.com"> */}
            {/* -> for ujjwal.moodi.org */}
            {/* <GoogleOAuthProvider clientId="571154717148-hj4krg8d4na2sf2fejm3trc9o9tdpaom.apps.googleusercontent.com"> */}
            {/* for my.moodi.org */}
            <GoogleOAuthProvider clientId="571154717148-9bv0ul64uvk3mbiv0kqgk012in8jotat.apps.googleusercontent.com">
              <GoogleLogin
                width="20vw"
                useOneTap={true}
                onSuccess={handleCallbackResponse}
                onError={() => {
                  alert("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
        )}
        <div className="email_login" onClick={onEmailClick}>
          <p>Email Login</p>
        </div>
      </div>
      <div>
        <button
          ref={nextBtnRef}
          style={{ display: "none" }}
          type="submit"
          onClick={handleSubmit}
          className="btn next-btn"
        ></button>
      </div>
    </div>
  );
}

export default LoginInfo;
