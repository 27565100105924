import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Ensure this is the correct import
import Random_assets from "./Random_assets";
import one from "../../../assets/images/ccp/avatar/one.svg";
import two from "../../../assets/images/ccp/avatar/two.svg";
import three from "../../../assets/images/ccp/avatar/three.svg";
import four from "../../../assets/images/ccp/avatar/four.svg";
import five from "../../../assets/images/ccp/avatar/five.svg";
import six from "../../../assets/images/ccp/avatar/six.svg";
import seven from "../../../assets/images/ccp/avatar/seven.svg";
import eight from "../../../assets/images/ccp/avatar/eight.svg";
import nine from "../../../assets/images/ccp/avatar/nine.svg";
import ten from "../../../assets/images/ccp/avatar/ten.svg";
import eleven from "../../../assets/images/ccp/avatar/eleven.svg";
import twelve from "../../../assets/images/ccp/avatar/twelve.svg";

const avatars = [
  { src: one, number: 1 },
  { src: two, number: 2 },
  { src: three, number: 3 },
  { src: four, number: 4 },
  { src: five, number: 5 },
  { src: six, number: 6 },
  { src: seven, number: 7 },
  { src: eight, number: 8 },
  { src: nine, number: 9 },
  { src: ten, number: 10 },
  { src: eleven, number: 11 },
  { src: twelve, number: 12 },
];

const SuccessPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("miauthtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setUser(decoded.user);
    } else {
      navigate("/register");
    }
  }, [navigate]);

  useEffect(() => {
    // Automatically navigate to /ccp/mymi after 2 seconds
    if (user) {
      const timer = setTimeout(() => {
        navigate("/ccp/mymi", { state: { user } });
      }, 1000);

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [user, navigate]);

  const getAvatarSrc = (imageNumber) => {
    const avatar = avatars.find((avatar) => avatar.number === imageNumber);
    return avatar ? avatar.src : null;
  };

  if (!user) return null;

  return (
    <div>
      <Random_assets />
      <div className="success_full">
        <div className="">
          <div className="welcome">Welcome to Indigo Squad</div>
          <div className="success_container">
            <div className="avatar_image">
              {user.image && (
                <img
                  src={getAvatarSrc(user.image)}
                  alt={`Avatar ${user.image}`}
                />
              )}
            </div>
            <div>
              <div className="mi_number">
                <p className="">Your MI Number is: {user.MI_NO}</p>
              </div>
              <div className="">
                <p className="">Your Referral Code is: {user.referralCode}</p>
                <p className="" style={{ textAlign: "center" }}>
                  Refer your friends and earn CCP Points!
                </p>
              </div>
            </div>
            {/* Remove Dashboard button */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
