import React, { useEffect, useRef, useState } from "react";
import Cross from "../../../assets/images/accommodation/Cross.png";
import mi_logo from "../../../assets/images/ccp/mood_logo.png";
import Iap_home from "../../../assets/images/iap/iap_n.png";
import Star from "../../../assets/images/iap/star.svg";
import Ticket from "../../../assets/images/iap/ticket.svg";
import Reg from "../../../assets/images/iap/ref.svg";
import Large from "../../../assets/images/iap/large.svg";
import Kid_one from "../../../assets/images/iap/kid_one.svg";
import Kid_two from "../../../assets/images/iap/kid_two.svg";
import Kid_three from "../../../assets/images/iap/kid_threee.svg";
import Graffiti from "../../../assets/images/iap/graffiti.svg";
import Second from "../../../assets/images/iap/second.svg";
import IAP_info from "../../../assets/images/iap/iap_in.svg";
import Text from "../../../assets/images/iap/text.png";
import Bottom from "../../../assets/images/iap/bottom.png";
import Icon from "../../../assets/images/iap/icon.svg";
import IAP_heading from "../../../assets/images/iap/what_hed.png";
import Incentive from "../../../assets/images/iap/incentive.png";
import Icen_a from "../../../assets/images/iap/icen_a.png";
import Icen_b from "../../../assets/images/iap/incen_b.png";
import atwork from "../../../assets/images/iap/atwork.svg";
import Certificate from "../../../assets/images/iap/certificate.svg";
import Global from "../../../assets/images/iap/global.svg";
import Mentor from "../../../assets/images/iap/mentor.svg";
import Screw from "../../../assets/images/iap/screw.svg";
import Perk from "../../../assets/images/iap/perk.svg";
import Light from "../../../assets/images/iap/light.svg";
import Test_Bottom from "../../../assets/images/compi/test_button.png";
import Sharvari from "../../../assets/images/iap/sharvari.jpg";
import Test_One from "../../../assets/images/iap/test_final.png";
import Mi_ex from "../../../assets/images/iap/mi_logo.svg";
import Team from "../../../assets/images/iap/team.svg";
import Solo from "../../../assets/images/iap/solo.svg";
import Iap_offer from "../../../assets/images/iap/iap_offer.png";
import { FaBars, FaTimes } from "react-icons/fa";
import {
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import RollingAnimation from "../Home/HomeComponents/RollingAnimation";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { jwtDecode } from "jwt-decode";
import IapTeamReg from "./IapTeamReg";
import Ext_tes from "../../../assets/images/iap/ext_inv.png";
import wall_gif from "../../../assets/images/iap/wall-1.gif";
import pro_gif from "../../../assets/images/iap/pm1.gif";
import installtion_gif from "../../../assets/images/iap/installation1.gif";

import testimonila from "../../../assets/images/iap/testimonials.png";
import "./iap.css";
import "./navbarIAP.css";
import "./new.css";
import IAF from "../../../assets/images/compi/lyp/IAF.jpg";

const IndexIAP = () => {
  const navigate = useNavigate();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showIap, setShowIap] = useState(false);
  const [soloReg, setSoloReg] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const [teamselected, setTeamSelected] = useState("");
  const [disabled, setDisabled] = useState(false);
  const lastpageRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    // fontSize: "8vw",
    // color: "#F5930C",
    // fontWeight: "700",
    // fontFamily: "Poppins",
    // lineHeight: "1.18",
    // WebkitTextStrokeWidth: "1px",
    // WebkitTextStrokeColor: "#000",
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };

  const [user, setUser] = useState(
    localStorage.getItem("miauthtoken")
      ? jwtDecode(localStorage.getItem("miauthtoken")).user
      : null
  );

  const homeNav = () => {
    navigate("/");
  };
  const slides = [
    {
      main: "WALL PAINTINGS",
      image: wall_gif,
      heading:
        "Mood Indigo 2024 is all set to transform the walls of the event into vibrant canvases of creativity. You will form teams to work alongside professional graf fiti artists from different parts of the world to develop ideas and bring them to life.",
    },
    {
      main: "INSTALLATIONS",
      image: installtion_gif,
      heading:
        "Get an on-ground experience like never before and see your ideas turning into reality at Mood Indigo 2024. These installations serve as the heartbeat of our festivals ambiance and breathing life into every corner.",
    },
    {
      main: "PROJECT MOTION",
      image: pro_gif,
      heading:
        "Mood Indigo 2024 takes innovation and creativity to new heights with Project Motion, a initiative that seamlessly blends technology and art to create the perfect immersive and interactive ambiance like no other",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    const interval = setInterval(nextSlide, 8000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup the interval when the component unmounts
  }, []);

  const handleOpenForm = () => {
    if (user) {
      setIsFormVisible(true);
      setShowIap(false);
      getRegProgram();
    } else {
      navigate(`/register?redirect=iap  `);
      setIsFormVisible(true);
      setShowIap(false);
    }
  };

  const handleFormclose = () => {
    setIsFormVisible(false);
    setShowIap(false);
  };

  const onCompetitionSelect = (event) => {
    setSelectedCompetition(event.target.value);
  };

  const onTeamCompetitionSelect = (event) => {
    setTeamSelected(event.target.value);
  };

  const [sidebar, setSidebar] = useState(false);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };
  const getRegProgram = () => {
    Axios.post(
      "https://ashish.moodi.org/iap/get_solo_reg",
      {
        user: user.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => setSoloReg(res.data))
      .catch((err) => console.error(err));
  };

  const onSoloreg = () => {
    if (selectedCompetition === "") {
      window.alert("Please select a competition");
      return;
    }
    if (soloReg.find((obj) => obj.program === selectedCompetition)) {
      window.alert("You are already registered for the competition");
      return;
    }

    setDisabled(true);
    if (localStorage.getItem("miauthtoken")) {
      Axios.post(
        "https://ashish.moodi.org/iap/solo_reg",
        {
          user: user.userId,
          program: selectedCompetition,
          userName: user.userName,
          email: user.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            window.alert(`Registration for ${selectedCompetition} Successful!`);
            setSoloReg([...soloReg, { program: selectedCompetition }]);
            setDisabled(false);
            window.reload();
          } else {
            window.alert(res.data.status);
            setDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
      navigate("/register?redirect=iap#iap_reg");
    }
  };

  const onRegisterClick = () => {
    if (teamselected === "") {
      window.alert("Please select a competition");
      return;
    }

    setShowIap(true);
  };

  const handleLogo = (url) => {
    window.location.href = url;
  };

  return (
    <div>
      <div id="page_1" className="iap_container">
        <img src={Iap_home} alt="ner" className="iap_home_img" />
        <img src={Star} alt="star" className="star_img_a" />
        <img src={Star} alt="star" className="star_img_b" />
        <img src={Star} alt="star" className="star_img_c" />
        <img src={Star} alt="star" className="star_img_d" />
      </div>

      <div className="wall_main_iap">
        <div id="ticket" className="ticket_main_iap">
          <div style={{ display: "flex" }}>
            <img
              src={Ticket}
              alt="ticket"
              className="ticket_a"
              style={{ transform: "rotate(1deg" }}
            />
            <div className="ticket_reg mobile_none" onClick={handleOpenForm}>
              <img
                src={Ticket}
                alt="ticket"
                className="ticket_a"
                style={{ transform: "rotate(-3deg)", cursor: "pointer" }}
              />
              <img src={Reg} alt="reg" className="reg_iap" />
            </div>
            <img src={Ticket} alt="ticket" className="ticket_a" />
            <div className="ticket_reg mobile_none_a" onClick={handleOpenForm}>
              <img
                src={Ticket}
                alt="ticket"
                className="ticket_a"
                style={{ transform: "rotate(-3deg" }}
              />
              <img src={Reg} alt="reg" className="reg_iap" />
            </div>
            <img src={Ticket} alt="ticket" className="ticket_a" />

            <img
              src={Ticket}
              alt="ticket"
              className="ticket_a"
              style={{ transform: "rotate(-3deg" }}
            />
          </div>
        </div>

        <div className="kid_div">
          <img src={Kid_one} alt="kid one" className="kid_one" />
          <img src={Kid_three} alt="kid one" className="kid_three" />
          <img src={Kid_two} alt="kid one" className="kid_two" />
        </div>

        <img src={Graffiti} alt="graffiti" className="graffiti_iap" />
        <img src={Large} alt="larger" className="large_iap" />
        <img src={Second} alt="second" className="second_iap" />
        <img src={Bottom} alt="bottom" className="bottom_ap" />

        <div className="iap_info_div">
          <img src={IAP_info} alt="iap_info" className="iap_info" />
          <img src={Text} alt="iap_info" className="iap_text" />
        </div>
      </div>

      <div className="animation_iap">
        <img src={IAP_heading} alt="iap_heading" className="iap_heading" />
        <div className="ani_a">
          <p className="ani_two_a_heading">{slides[currentIndex].main}</p>
        </div>
        <div className="ani_b">
          <div
            className="ani_one"
            style={{
              background: `linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 100%), 
                 url(${
                   slides[(currentIndex - 1 + slides.length) % slides.length]
                     .image
                 }) lightgray 50% / cover no-repeat`,
            }}
          >
            <img
              src={Icon}
              alt="icon"
              style={{ transform: "rotate(-180deg)" }}
              onClick={prevSlide}
            />
          </div>
          <div
            className="ani_two"
            style={{
              background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%), 
                 url(${slides[currentIndex].image}) lightgray 50% / cover no-repeat`,
            }}
          >
            <div className="ani_two_a">
              <p className="ani_two_b_head">{slides[currentIndex].heading}</p>
              <button className="iap_button" onClick={handleOpenForm}>
                Register
              </button>
            </div>
          </div>
          <div
            className="ani_three"
            style={{
              background: `linear-gradient(-90deg, rgba(0, 0, 0, 0) 40.65%, #000 100%), 
                 url(${
                   slides[(currentIndex + 1) % slides.length].image
                 }) lightgray 50% / cover no-repeat`,
            }}
          >
            <img src={Icon} alt="icon" onClick={nextSlide} />
          </div>
        </div>
        <div className="ani_c">
          <img src={Iap_offer} className="iap_ooffer" alt="icon" />
        </div>
      </div>

      <div className="incentives">
        <img src={Incentive} alt="incentive" className="incentive_iap" />
        <div className="incentive_a">
          <img src={Icen_a} alt="incentive" className="incen_a" />
          <img src={Icen_b} alt="incentive" className="incen_a" />
          <div className="global ext_glo_iap">
            <p className="global_heading">NETWORKING</p>
            <img src={Global} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Seize the opportunity to collaborate with professional
              International artists and connect with peers from 100+ colleges
              across the nation
            </p>
          </div>
          <div className="global ext_men_iap">
            <p className="global_heading">MENTORSHIP</p>
            <img src={Mentor} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Gain invaluable insights and benefit from the guidance of experts
              in various art forms
            </p>
          </div>
          <div className="global ext_per_iap">
            <p className="global_heading">FEST PERKS</p>
            <img src={Perk} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Enjoy free accommodation and a chance to win VIP concert passes at
              Mood Indigo 2024
            </p>
          </div>
          <div className="global ext_at_iap">
            <p className="global_heading">ATWORK VISIBILTY</p>
            <img src={atwork} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Secure a chance to exhibit your artwork at various Art galleries
            </p>
          </div>
          <div className="global ext_unc_iap">
            <p className="global_heading">UNCONVENTIONAL WORKSHOPS</p>
            <img src={Screw} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Dive into unique learning experiences through workshops on
              unconventional art forms
            </p>
          </div>
          <div className="global ext_res_iap">
            <p className="global_heading">RESUMES AND CERTIFICATES</p>
            <img src={Certificate} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Earn certifications for your participation in various festival
              activities.
            </p>
          </div>
        </div>
        <img src={Star} alt="start" className="star_incentive_a" />
        <img src={Star} alt="start" className="star_incentive_b" />
        <img src={Star} alt="start" className="star_incentive_c" />

        <div className="mobile_incentive">
          <div className="global ext_glo_iap">
            <p className="global_heading">NETWORKING</p>
            <img src={Global} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Seize the opportunity to collaborate with professional artists and
              connect with peers
            </p>
          </div>
          <div className="global ext_men_iap">
            <p className="global_heading">MENTORSHIP</p>
            <img src={Mentor} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Gain invaluable insights and benefit from the guidance of experts
              in various art forms
            </p>
          </div>
          <div className="global ext_per_iap">
            <p className="global_heading">FEST PERKS</p>
            <img src={Perk} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Enjoy free accommodation and a chance to win VIP concert passes at
              Mood Indigo 2024.
            </p>
          </div>
        </div>
        <div className="mobile_incentive_b">
          <div className="global ext_at_iap">
            <p className="global_heading">ATWORK VISIBILTY</p>
            <img src={atwork} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Secure a chance to exhibit your artwork at various Art galleries
            </p>
          </div>
          <div className="global ext_unc_iap">
            <p className="global_heading">UNCONVENTIONAL WORKSHOPS</p>
            <img src={Screw} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Dive into unique learning experiences through workshops on
              unconventional art forms
            </p>
          </div>
          <div className="global ext_res_iap">
            <p className="global_heading">RESUMES AND CERTIFICATES</p>
            <img src={Certificate} alt="global" className="global_iap" />
            <p className="global_heading_a">
              Earn valuable certifications by actively participating in festival
              activities.
            </p>
          </div>
        </div>
      </div>

      <div className="incen_next">
        <img src={Ext_tes} alt="nex" className="incen_next_img" />
        <div className="incen_next_a">
          <p className="head_in">LIVE YOUR PASSION</p>
          <img
            onClick={() => handleLogo("https://indiaartfestival.com/")}
            src={IAF}
            alt="nitri"
            className="lyp_logo_iap"
          />
          <p className="head_in_a">
            Get a chance to showcase your projects at the India Art Festival and
            also get free 1-year subscription to their art journal
          </p>
        </div>
      </div>

      <div className="test_iap">
        <img src={testimonila} alt="light" className="incentive_iap" />
        <img src={Light} alt="light" className="light_iap" />
        <img src={Test_Bottom} alt="test_botton" className="test_bottombot" />
        <img src={Test_One} alt="test_one" className="test_one" />
      </div>

      <div className="iap_footer">
        <div className="card_iap_main">
          <div className="card_iap" style={{ background: "#705e0f" }}></div>
          <div
            className="card_iap"
            style={{
              background: "#769736",
              transform: "rotate(-5deg)",
              transformOrigin: "bottom right",
            }}
          ></div>
          <div
            className="card_iap"
            style={{
              background: "#fff",
              transform: "rotate(-10deg)",
              transformOrigin: "bottom right",
            }}
          >
            {" "}
            <img src={Sharvari} alt="cg_iap" className="cg_iap_image" />
            <div>
              {" "}
              <div className="cg-contact">
                <p className="cg-name iap_cg_name">SHARVARI DHOTE</p>
                <p>Ambience Head</p>
                <a href="tel:+918830244398" className="cg-phone iap_cg-phone">
                  +91 88302 44398
                </a>
              </div>
              <div className="cg-links">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/_sharvari_dhote_?igsh=bXppcnZ5MnpsOTB1&utm_source=qr"
                >
                  <FaInstagram color="black" size={30} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/sharvari-dhote-971765258/"
                >
                  <FaLinkedin color="black" size={30} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/sharvari_dhote"
                >
                  <FaTwitter color="black" size={35} />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/8830244398"
                >
                  <FaWhatsapp color="black" size={30} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="iap_footer_details" sty>
          <p className="iap_heading_footer">ABOUT US</p>
          <p className="iap_heading_footer_a">
            Mood Indigo is not just the annual cultural fest of IIT Bombay but a
            kaleidoscope of emotions, memories, and boundless creativity. This
            'Chaar Din ka Sapna' is the place where people experience four power
            packed days of passionate competitions, fun games and activities,
            events of renowned artists, electrifying concerts and soothing night
            life. Over the 53 years that Mood Indigo has established its legacy
            in, it has served host to artists like Deepika Padukone, Vidya
            Balan. Magical concerts of Mohit Chauhan, Vishal Shekhar have awed
            the audience. All in all, MI is a testament to the indomitable
            spirit of exploration and self-expression, etching lasting memories
            in the hearts of all who partake in its magic.
          </p>
        </div>
      </div>

      <div
        ref={lastpageRef}
        className="footer iap_extra_footer"
        style={{ background: "#7D101B" }}
      >
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div className="footer_details" style={{ background: "#5E0E11" }}>
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
      {isFormVisible && (
        <div className="iap_form">
          <div className="iap_form_details">
            <img
              onClick={handleFormclose}
              src={Cross}
              alt="cross"
              className="cross_iap"
            />
            <img src={Mi_ex} alt="mi_ex" className="mi_iap_log" />
            <img src={Reg} alt="reg" className="reg_for_iap" />

            <div className="team_iap_reg">
              <div
                onClick={!disabled ? onSoloreg : null}
                className="solo_iap_reg_a"
                style={{
                  cursor: disabled ? "not-allowed" : "pointer",
                  opacity: disabled ? 0.6 : 1,
                }}
              >
                <img src={Solo} alt="team" style={{ width: "100%" }} />
                <button className="iap_button" style={{ marginBottom: "0vh" }}>
                  Submit
                </button>
              </div>
              <div className="solo_iap_reg_b">
                <select
                  id="competition"
                  value={selectedCompetition}
                  onChange={onCompetitionSelect}
                >
                  <option value="">-- Select --</option>
                  <option value="Installation">Installation</option>
                  <option value="Project Motion">Project Motion</option>
                  <option value="Wall Painting">Wall Painting</option>
                </select>
              </div>
            </div>
            <div className="solo_iap_reg">
              <div onClick={onRegisterClick} className="solo_iap_reg_a">
                <img src={Team} alt="team" style={{ width: "90%" }} />
                <button className="iap_button" style={{ marginBottom: "0vh" }}>
                  Submit
                </button>
              </div>
              <div className="solo_iap_reg_b">
                <select
                  id="competition"
                  value={teamselected}
                  onChange={onTeamCompetitionSelect}
                >
                  <option value="">-- Select --</option>
                  <option value="Installation">Installation</option>
                  <option value="Project Motion">Project Motion</option>
                  <option value="Wall Painting">Wall Painting</option>
                </select>
              </div>
            </div>
            <div className="iap_Reg_p">
              <p>
                Mood Indigo 2024 is all set to transform the walls of the event
                into vibrant canvases of creativity. You will form teams to work
                alongside professional graffiti artists from different part of
                the world from developing ideas to bringing out them to life.
                Join us and be a part of this collaborative, and creative
                celebration of art!
              </p>
            </div>
          </div>
          {showIap && (
            <IapTeamReg
              program={teamselected}
              setShowIap={setShowIap}
              setprogram={teamselected}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default IndexIAP;
